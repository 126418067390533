import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { DateTime } from 'luxon'
import Layout from '@c/layout'
import Breadcrumb from '@c/breadcrumb'
import RedLine from '@c/red-line'
import AuthorCard from '@c/author-card'
import Video from '@c/video'
import ShareBox from '@c/share-box'
import VideoModel from '@m/Video'
import makeSlug from '@u/make-slug'
import ResourceAuthorCard from '@c/resource-author-card'

import './detail.css'

const Page = ({ data, pageContext }) => {
  const { pageSettings, video } = data
  const { title, author } = video
  const {
    resourcesSlug,
    resourcesTitle,
    resourcesVideoSlug,
    resourcesVideoTitle,
    categorySlug
  } = pageSettings
  const model = new VideoModel(video)

  const parentPages = [
    {
      url: makeSlug.generic(pageContext.locale, resourcesSlug),
      title: resourcesTitle
    },
    {
      url: makeSlug.generic(
        pageContext.locale,
        resourcesSlug,
        resourcesVideoSlug
      ),
      title: resourcesVideoTitle
    }
  ]

  const url = `https://zeo.org${model.getPath(pageSettings)}`
  const facebookLink = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    url
  )}`
  const twitterLink = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
    title
  )}&url=${encodeURIComponent(url)}`
  const linkedinLink = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
    url
  )}`
  const whatsappLink = `whatsapp://send?text=${encodeURIComponent(url)}`
  const mailLink = `mailto:?subject=${encodeURIComponent(
    title
  )}&body=${encodeURIComponent(url)}`
  const created = DateTime.fromFormat(video.createdAt, 'yyyy-MM-dd').toFormat(
    'dd MMM yyyy',
    { locale: pageContext.locale }
  )
  const categoryBase = makeSlug.generic(
    pageContext.locale,
    resourcesSlug,
    resourcesVideoSlug,
    categorySlug
  )

  return (
    <Layout
      locale={pageContext.locale}
      seo={{ ...model.getSeoComponentProps(pageSettings) }}
    >
      {/* BREADCRUMB */}
      <div className="container mb-60">
        <div className="row align-items-center">
          <div className="col-12 col-lg-6">
            <Breadcrumb
              className="resource-detail-breadcrumb"
              parentPages={parentPages}
              currentPage={title}
            />
          </div>
          <div className="col-12 col-lg-6 d-flex flex-wrap align-items-center justify-content-end mt-32 mt-lg-0 ml-auto">
            <AuthorCard
              photo={get(author, 'profileImage')}
              alt={get(author, 'profileImage.alt') || ''}
              name={`${author.firstName} ${author.lastName}`}
              nickname={author.nickname}
              categoryBase={categoryBase}
              categories={video.categories}
              locale={pageContext.locale}
            />
            <p className="fs-small ml-32">{created}</p>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-12">
            {/* video*/}
            <div className="d-flex">
              <Video
                totalTime={video.duration}
                videoId={video.video.providerUid}
                embed={true}
              />
              <ShareBox
                direction="v"
                facebookLink={facebookLink}
                twitterLink={twitterLink}
                mailLink={mailLink}
                whatsappLink={whatsappLink}
                linkedinLink={linkedinLink}
              />
            </div>
            {/* start-title */}
            <div className="row mt-32 mt-lg-0">
              <div className="col-12 col-lg-9 px-16 px-lg-100 py-lg-32 bg-white">
                <h1 className="bg-white">{title}</h1>
                <RedLine />
              </div>
            </div>
            {/* end-title */}

            {/* content */}
            <div className="row mt-16">
              <div className="col-12 col-lg-10 offset-lg-1">
                {/* start-body */}
                <div
                  className="video-content"
                  dangerouslySetInnerHTML={{ __html: data.video.description }}
                />
                {/* end-body */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ResourceAuthorCard
        locale={author.locale}
        author={author}
        nickname={author.nickname}
        photo={author.profileImage}
      />
    </Layout>
  )
}

export default Page

export const query = graphql`
  query ($videoId: String, $authorId: String, $locale: String) {
    video: datoCmsVideo(id: { eq: $videoId }) {
      _allSlugLocales {
        locale
        value
      }
      metaTags {
        ...MetaTags
      }
      locale
      slug
      title
      description
      duration
      createdAt: createdAtCustom
      categories {
        title
        slug
      }
      video {
        title
        url
        thumbnailUrl
        providerUid
      }
      author {
        firstName
        lastName
        nickname
        jobPosition
        profileImage {
          alt
          asset {
            localImage {
              extension
              publicURL
              childImageSharp {
                gatsbyImageData(layout: FIXED, width: 30)
              }
            }
          }
        }
      }
    }

    # video author's other videos (only latest 2)
    authorsOtherVideos: allDatoCmsVideo(
      filter: {
        id: { ne: $videoId }
        locale: { eq: $locale }
        slug: { regex: "/.+/" } # prevent untranslated content to be retrived
        author: { id: { eq: $authorId } }
      }
      sort: { fields: [createdAtCustom], order: DESC }
      limit: 10
    ) {
      nodes {
        id
        _allSlugLocales {
          locale
          value
        }
        slug
        title
        categories {
          title
          slug
        }
      }
    }

    pageSettings: datoCmsPageSetting(locale: { eq: $locale }) {
      _allResourcesSlugLocales {
        locale
        value
      }
      _allResourcesVideoSlugLocales {
        locale
        value
      }
      resourcesTitle
      resourcesSlug
      resourcesVideoTitle
      resourcesVideoSlug
      categorySlug
    }

    resourcesPage: datoCmsResourcesPage(locale: { eq: $locale }) {
      allVideosLinkText
    }

    teamPage: datoCmsTeamPage(locale: { eq: $locale }) {
      slug
    }

    translation: datoCmsTranslation(locale: { eq: $locale }) {
      relatedVideosTitleFirst: videoDetailsPageRelatedVideosTitleFirst
      relatedVideosTitleSecond: videoDetailsPageRelatedVideosTitleSecond
    }
  }
`
