import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import getContentByLocale from '@u/get-content-by-locale'
import makeSlug from '@u/make-slug'

import './index.css'
import classNames from 'classnames'
import ProjectImage from '../project-image'

const query = graphql`
  query {
    teamPage: allDatoCmsTeamPage {
      edges {
        node {
          locale
          slug
        }
      }
    }
  }
`
function AuthorImage({ author, photo, small, nickname, locale, children }) {
  const {
    teamPage: { edges: teamPageEdges }
  } = useStaticQuery(query)

  const teamPage = getContentByLocale(locale, teamPageEdges, 'node.locale')
    ?.node
  return (
    <div className={'d-flex align-items-center'}>
      {!!author?.jobPosition && (
        <Link
          to={makeSlug.generic(locale, teamPage.slug, nickname)}
          className="d-flex align-items-center"
        >
          {photo && (
            <ProjectImage
              className={classNames('author-img', { small: small })}
              image={photo}
              alt="zeo-author"
            />
          )}
          {!!children && <span className={'ml-16'}>{children}</span>}
        </Link>
      )}
      {!photo && (
        <div className={classNames('author-img', { small: small })}></div>
      )}
      {!!children && !author?.jobPosition && (
        <span className={'ml-16'}>{children}</span>
      )}
    </div>
  )
}
export { AuthorImage }
function AuthorAvatar({ author, photo, nickname, locale, smallAvatar }) {
  return (
    <div className="d-flex flex-wrap justify-content-center align-items-center col-12">
      <AuthorImage
        author={author}
        small={smallAvatar}
        photo={photo}
        nickname={nickname}
        locale={locale}
      />
      <div className="d-flex flex-column ml-16 mt-16 mt-lg-0">
        <span className="fs-large">
          {author?.firstName} {author?.lastName}
        </span>
        <span className="pl-0 mt-8 fs-text-small fw-text-light">
          {author?.jobPosition}
        </span>
      </div>
    </div>
  )
}

export default AuthorAvatar
