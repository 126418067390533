import React from 'react'
import cn from 'classnames'

import Icon from '../icon'

import './index.css'

const ShareBox = ({
  className,
  direction = 'h',
  facebookLink,
  twitterLink,
  linkedinLink,
  instagramLink,
  mailLink,
  whatsappLink
}) => {
  return (
    <div
      className={cn(
        'share-box d-inline-flex align-items-center',
        `direction-${direction}`,
        className
      )}
    >
      {twitterLink && (
        <a
          className={'link-twitter'}
          href={twitterLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          {<Icon name="twitter" />}
        </a>
      )}

      {facebookLink && (
        <a
          className={'link-facebook'}
          href={facebookLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          {<Icon name="facebook" />}
        </a>
      )}

      {instagramLink && (
        <a
          className={'link-instagram'}
          href={instagramLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          {<Icon name="instagram" />}
        </a>
      )}

      {linkedinLink && (
        <a
          className={'link-linkedin'}
          href={linkedinLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          {<Icon name="linkedin" />}
        </a>
      )}

      {mailLink && (
        <a
          className={'link-mail'}
          href={mailLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          {<Icon name="mail" />}
        </a>
      )}

      {whatsappLink && (
        <a
          className={'link-whatsapp'}
          href={whatsappLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          {<Icon name="whatsapp" />}
        </a>
      )}
    </div>
  )
}

ShareBox.defaultProps = {}

ShareBox.propTypes = {}

export default ShareBox
