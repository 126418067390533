import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import ShareBox from '@c/share-box'
import Icon from '@c/icon'
import getContentByLocale from '@u/get-content-by-locale'
import './index.css'
import AuthorAvatar from '../author-avatar'

const query = graphql`
  query {
    translations: allDatoCmsTranslation {
      edges {
        node {
          locale
          subscribeToZeoLinkText
        }
      }
    }
  }
`

function ResourceAuthorCard({ author, photo, nickname, locale }) {
  const {
    translations: { edges: translationEdges }
  } = useStaticQuery(query)
  const translation = getContentByLocale(
    locale,
    translationEdges,
    'node.locale'
  )?.node
  return (
    <div className="bg-soft-blue mt-60 py-60">
      <div className="container">
        <div className="row">
          {/* Left side */}
          <div className="d-flex mt-20">
            <AuthorAvatar
              author={author}
              photo={photo}
              nickname={nickname}
              locale={locale}
            />
            <span className="pl-0 col-3">
              <ShareBox
                facebookLink={author?.facebookLink}
                twitterLink={author?.twitterLink}
              />
            </span>
          </div>
          {/** End of Left side */}

          {/* Right side */}
          <div className="d-flex ml-auto mt-32 mr-32">
            <a href="#subscribe-zeo">
              {translation?.subscribeToZeoLinkText}
              <br />
              <Icon className="c-red" />
            </a>
          </div>
          {/** End of Right side */}
        </div>
      </div>
    </div>
  )
}

export default ResourceAuthorCard
