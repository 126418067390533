import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import cn from 'classnames'

import RedLine from './red-line'

const Breadcrumb = ({
  parentPages,
  currentPage,
  className,
  wrapCurrentPageWithH1 = false
}) => (
  <div className={cn('Breadcrumb', className)}>
    {parentPages.length > 0 && (
      <p>
        {parentPages.map(page => (
          <Link key={page.url} to={page.url}>
            {page.title} /{' '}
          </Link>
        ))}
      </p>
    )}

    {wrapCurrentPageWithH1 ? (
      <h1 className="h4">{currentPage}</h1>
    ) : (
      <p className="h4">{currentPage}</p>
    )}

    <RedLine className="mt-16" />
  </div>
)

Breadcrumb.defaultProps = {
  parentPages: ['Zeo']
}

Breadcrumb.propTypes = {
  className: PropTypes.string,
  parentPages: PropTypes.array,
  currentPage: PropTypes.string.isRequired
}

export default Breadcrumb
